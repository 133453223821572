<template>
  <el-button
    class="safie-default-button"
    :loading="loading"
    :loading-icon="loadingIcon"
    :icon="icon"
    :class="buttonClass"
    :disabled="disabled"
    :style="buttonStyle"
    data-testid="safie-default-button-el-button"
  >
    <slot name="icon"></slot>
    <slot></slot>
  </el-button>
</template>
<script setup lang="ts">
import { Component, computed, PropType, StyleValue } from "vue";
const props = defineProps<{
  type?:
    | "primary"
    | "second"
    | "primary-danger"
    | "second-danger"
    | "delete"
    | "cancel";
  size?:
    | "small-28"
    | "small-32"
    | "small-35"
    | "large-40"
    | "large-48"
    | "large-52";
  preset?: "dedicated-option";
  width?: number;
  loading?: boolean;
  disabled?: boolean;
  icon?: PropType<string | Component>;
  loadingIcon?: PropType<string | Component>;
}>();
const buttonClass = computed(() => {
  let newClass = "";
  if (props.type) {
    newClass += props.type + " ";
  }
  if (props.size) {
    newClass += props.size;
  }
  if (props.preset) {
    newClass += props.preset;
  }
  return newClass.trim();
});
const buttonStyle = computed(() => {
  const newStyle: StyleValue = {};
  if (props.width) {
    if (props.width <= 100) {
      newStyle.width = props.width + "%";
    } else {
      newStyle.width = props.width + "px";
    }
  }
  return newStyle;
});
</script>
<style lang="scss" scoped>
// primary
.primary-default {
  color: var(--safie-white-000);
  border: none;
  outline: 0;
}
.second-default {
  border: 1px solid var(--safie-green-400);
  background: var(--safie-white-000);
  color: var(--safie-green-400);
}
.delete-default {
  border: 1px solid var(--safie-active-text);
  background: var(--safie-white-000);
  color: var(--safie-active-text);
}
.cancel-default {
  border: 1px solid var(--safie-active-text);
  background: var(--safie-white-000);
  color: var(--safie-active-text);
}

.safie-default-button,
.primary {
  @extend .primary-default;
  background: var(--safie-green-400);
  height: 40px;
  padding: 0 24px;
  border-radius: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  &:hover {
    @extend .primary-default;
    background: var(--safie-green-300);
  }
  &:active {
    @extend .primary-default;
    background: var(--safie-green-500);
  }
  &.is-disabled,
  &.is-disabled:hover,
  &.is-disabled:active {
    @extend .primary-default;
    background: var(--safie-white-300);
  }
  &.primary-danger {
    background: var(--safie-status-danger);
    &:hover,
    &:active {
      background: var(--safie-status-danger);
    }
  }
  &.second {
    @extend .second-default;
    &:hover {
      @extend .second-default;
      background: var(--safie-green-100);
    }
    &:active {
      border-color: var(--safie-green-500);
      background: var(--safie-green-100);
      color: var(--safie-green-500);
    }
    &.is-disabled,
    &.is-disabled:hover,
    &.is-disabled:active {
      @extend .second-default;
      border: 1px solid #e4e7ed;
      color: var(--safie-white-300);
    }
  }
  &.second-danger {
    @extend .second-default;
    border-color: var(--safie-status-danger);
    color: var(--safie-status-danger);
    &:hover,
    &:active {
      @extend .second-default;
      border-color: var(--safie-status-danger);
      color: var(--safie-status-danger);
    }
    &.is-disabled,
    &.is-disabled:hover,
    &.is-disabled:active {
      @extend .second-default;
      border: 1px solid var(--safie-white-300);
      color: var(--safie-white-300);
    }
  }

  &.delete {
    @extend .delete-default;
    &:hover,
    &:active,
    &.is-disabled,
    &.is-disabled:hover,
    &.is-disabled:active {
      @extend .delete-default;
    }
  }
  &.cancel {
    @extend .cancel-default;
    &:hover,
    &:active,
    &.is-disabled,
    &.is-disabled:hover,
    &.is-disabled:active {
      @extend .cancel-default;
    }
  }
}

.el-button + .cancel {
  margin-left: 0;
}

.dedicated-option {
  height: 40px;
  margin-left: 28px;
}

.small-28 {
  border-radius: 14px;
  height: 28px;
  padding: 0 16px;
  font-weight: 500;
}
.small-32 {
  padding: 0 16px;
  border-radius: 16px;
  height: 32px;
}
.small-35 {
  border-radius: 17.5px;
  height: 35px;
}
.large-40 {
  border-radius: 20px;
  height: 40px;
  min-width: 200px;
}
.large-48 {
  border-radius: 24px;
  height: 48px;
  font-size: 15px;
}
.large-52 {
  border-radius: 26px;
  height: 52px;
  min-width: 200px;
}

@media screen and (max-width: 768px) {
  .safie-default-button,
  .primary {
    &:hover {
      background: var(--safie-green-400);
    }
    &:active {
      background: var(--safie-green-500);
    }
    &.second {
      &:hover {
        background: var(--safie-white-000);
      }
      &:active {
        background: var(--safie-green-100);
      }
    }
  }
}
</style>
